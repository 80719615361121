export const ROOT: string = '/';
export const LATEST_REPORTS: string = '/latest-company-reports';
export const INDUSTRY_AVERAGES: string = '/industry-averages';
export const COMPANY_SEARCH_RESULT: string = '/company-search-result';
export const COMPANY: string = '/company';
export const COMPANY_SECTION: string =
  '/company/:publicID/:section?/:subsection?/:companyName?/:organizationNumber?';
export const DISCLAIMER_PAGE: string = '/disclaimer';
export const ADD_ESG_REPORT: string = '/add-esg-report';
export const GREETINGS_FROM_STAMDATA: string = '/greetings-from-stamdata';
export const PRODUCTS: string = '/products';
export const USER_TERMS: string = '/user-terms';
export const LEAGUE_TABLE: string = '/league-table';
export const LEAGUE_TABLE_SECTION: string =
  '/league-table/:definitionCode?/:leagueTable?';
export const NOT_AVAILABLE: string = '/not-available';
export const NOT_FOUND: string = '/not-found';
export const LEARNING_CENTER: string = '/learning-center';
export const LEARNING_CENTER_FAQ: string = '/learning-center/faq';
export const LEARNING_CENTER_ESG_FUNDAMENTALS: string =
  '/learning-center/esg-fundamentals';
export const LEARNING_CENTER_SECTION: string =
  '/learning-center/:section?/:question?';
export const ACCESS_BLOCKED: string = '/access-blocked';